<script setup lang="ts">
import {onMounted, onUnmounted} from "vue";

//Main reveal.js css
import "reveal.js/dist/reveal.css";
//Reveal.js theme
import "reveal.js/dist/theme/black.css";

import Reveal from "reveal.js";
import RevealMarkdown from "reveal.js/plugin/markdown/markdown";
import RevealNotes from "reveal.js/plugin/notes/notes";
import UseCases from "./slides/UseCases.vue";
import History from "./slides/History.vue";
import FirstSlide from "./slides/FirstSlide.vue";
import Chat from "./slides/Chat.vue";
import EthicalImplications from "./slides/EthicalImplications.vue";
import QA from "./slides/QA.vue";
import IsItIntelligent from "./slides/IsItIntelligent.vue";

onMounted(() => {
  Reveal.initialize({
    plugins: [RevealMarkdown, RevealNotes],
    parallaxBackgroundImage: "/background.png",
    parallaxBackgroundSize: 'cover',
    navigationMode: "linear",
    controls: false,
    hash: true,
    history: true,
    markdown: {
      breaks: true,
      gfm: true,
    },
  })
});

const glitchedWriterOptions = {
  letterize: true,
  interval: [20, 60]
};
</script>

<template>
  <div id="app">
    <div class="reveal">
      <div class="slides">
        <section>
          <FirstSlide/>
          <History/>
        </section>
        <UseCases/>
        <Chat/>
        <EthicalImplications/>
        <IsItIntelligent/>
        <QA/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  background-color: rgba(0, 0, 0, 0.15);
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
.subtitle{
  font-size: 0.4em;
  margin: 0 !important;
}
</style>
