<script setup lang="ts">
import HistoryNote from "../notes/historyNote.vue";
</script>

<template>
  <section data-auto-animate data-auto-animate-restart>
    <h1>HISTORY</h1>
    <aside class="notes">
      <HistoryNote/>
    </aside>
  </section>
  <section data-auto-animate>
    <h1>HISTORY</h1>
    <ul>
      <li>SYMBOLIC AI</li>
      <li style="visibility: hidden">MACHINE LEARNING</li>
    </ul>
    <aside class="notes">
      <HistoryNote/>
    </aside>
  </section>
  <section data-auto-animate>
    <h1>HISTORY</h1>
    <ul>
      <li>SYMBOLIC AI</li>
      <li>MACHINE LEARNING</li>
    </ul>
    <aside class="notes">
      <HistoryNote/>
    </aside>
  </section>
</template>