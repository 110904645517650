<script setup lang="ts">
import DefinitionNote from "../notes/definitionNote.vue";
</script>

<template>
  <section data-auto-animate data-autoslide="1500">
    <h1 class="glow-strong">{AI}</h1>
    <aside class="notes">
      <DefinitionNote/>
    </aside>
  </section>
  <section data-auto-animate>
    <h1 class="glow-strong">{AI}</h1>
    <h2 class="glow-light">Artificial Intelligence</h2>
    <aside class="notes">
      <DefinitionNote/>
    </aside>
  </section>
</template>