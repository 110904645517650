<script setup lang="ts">
let data = [
  {
    title: "Natural language",
    description: "Siri, Alexa, and Google Assistant"
  },
  {
    title: "Image and video recognition",
    description: "Tesla"
  },
  {
    title: "Chat bots",
    description: "ChatGPT, Amazon Lex"
  },
  {
    title: "Behaviour analytics",
    description: "Netflix, Prime video, Disney Plus, Instagram, TikTok, BeFake"
  },
  {
    title: "Recommendation systems",
    description: "Amazon, Spotify, Netflix, YouTube"
  }
]
</script>

<template>
  <section data-auto-animate data-auto-animate-restart>
    <h1>USE CASES</h1>
  </section>
  <section data-auto-animate v-for="i in data.length">
    <h1>USE CASES</h1>
    <ul>
      <li v-for="(entry, index) in data" :style="{visibility: i <= index ? 'hidden' : null}">{{entry.title}}<p class="subtitle">{{entry.description}}</p></li>
    </ul>
  </section>
</template>
