<script lang="ts" setup>
import {StyleValue} from "vue";

const props = defineProps({
  sent: Boolean,
  id: Number
})

const messageStyle: StyleValue = {};
const containerStyle: StyleValue = {};

if (props.sent) {
  messageStyle.borderRadius = '25px 0 25px 25px';
  messageStyle.marginLeft = '120px';
  containerStyle.textAlign = 'right';
} else {
  messageStyle.borderRadius = '0 25px 25px 25px';
  messageStyle.marginRight = '120px';
  containerStyle.textAlign = 'left';
}
</script>

<template>
  <div id="container" :style="containerStyle">
    <div v-if="!sent" id="logo">
      <slot name="pic"></slot>
    </div>

    <div id="message" :style="messageStyle" :data-id="'message' + id">
      <p data-auto-animate-duration="0">
        <slot></slot>
      </p>
    </div>

    <div v-if="sent" id="logo">
      <slot name="pic"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#container {
  white-space: nowrap;
}

#logo {
  vertical-align: top;
  display: inline-block;
}

#message {
  background-color: #242424;
  padding: 0 1.5vw 0 1.5vw;
  border-radius: 0 25px 25px 25px;
  margin: 10px;
  display: inline-block;

  p {
    font-size: 1.5vw;
    white-space: pre-wrap;
    text-align: left;
  }
}
</style>