<script setup lang="ts">
import DilemmaNote from "../notes/dilemmaNote.vue";
</script>

<template>
  <section data-auto-animate data-auto-animate-restart>
    <h2>AI INTELLIGENCE DILEMMA</h2>
    <aside class="notes">
      <DilemmaNote/>
    </aside>
  </section>
  <section data-auto-animate>
    <h2>AI INTELLIGENCE DILEMMA</h2>
    <img src="../assets/intelligence.png">
    <aside class="notes">
      <DilemmaNote/>
    </aside>
  </section>
</template>