<template>
  <section>
    <h1>Q<span id="and">&</span>A</h1>
  </section>
</template>

<style lang="scss">
#and{
  font-size: 0.1em;
  color: white;
  transition: all 3s;
}
.present{
  #and{
    font-size: 0.5em;
  }
}
.future{
  #and{
    transition: all 0s;
  }
}
</style>