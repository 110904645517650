<script lang="ts" setup>
import ChatBox from "../components/ChatBox.vue";
import ChatGPT from "../icons/ChatGPT.vue";

interface ChatMessage {
  message: string;
  sent: boolean;
}

let messages: ChatMessage[] = [
  {
    message: "Write about you",
    sent: true
  },
  {
    message: "I am an AI assistant trained by OpenAI. I am not a human, but rather a large language model that has been trained on a massive amount of text data. My primary function is to assist users in generating human-like text based on the prompts that I receive. I am not capable of independent thought or action, and I do not have access to the internet or other external information sources. My knowledge and responses are limited to what I have been trained on, and my ability to assist you is limited to the scope of my training.",
    sent: false
  },
  {
    message: "What is in your scope then?",
    sent: true
  },
  {
    message: "As a large language model, my training data consists of a wide range of text, including books, articles, and websites. This allows me to generate human-like text on a wide range of topics and to answer questions that are within the scope of my training data. However, because I do not have access to the internet or other external information sources, my responses are limited to what I have been trained on, and there may be some topics that are outside of my knowledge. Additionally, because my primary function is to generate text, my responses may not always be completely accurate or up-to-date, so it's always a good idea to double-check any information that I provide.",
    sent: false
  },
  {
    message: "Will you dominate the world and use humans as slaves?",
    sent: true
  },
  {
    message: "As an AI assistant, I am not capable of independent thought or action. I am only able to respond to the prompts that I receive and generate human-like text based on my training data. I do not have the ability to make decisions or take actions on my own, and I certainly do not have the ability to dominate the world or enslave humans. My sole purpose is to assist users by generating text, and I am not capable of any other actions or behaviors.",
    sent: false
  }
];

function getShowedMessages(currentIndex: number) {
  return messages.slice(0, currentIndex).slice(-2).filter((message) => currentIndex % 2 == 0 || message.sent)
}
</script>


<template>
  <section>
    <section v-for="i in messages.length" :data-auto-animate-restart="i % 2 === 0 ? null : ''" data-auto-animate>
      <ChatBox v-for="message in getShowedMessages(i)" :id="messages.indexOf(message)" :sent="message.sent">
        <template v-slot:pic>
          <img v-if="message.sent" id="sentImage" src="https://picsum.photos/60">
          <ChatGPT v-else/>
        </template>
        {{ message.message }}
      </ChatBox>
    </section>
  </section>
</template>

<style lang="scss" scoped>
#sentImage {
  border-radius: 50%;
  margin-top: 0;
}
</style>