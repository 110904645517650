<template>
  The AI dilemma is one that has been debated for a long time.<br>
  On one hand, AI can be incredibly powerful and can accomplish things that no human could.<br>
  On the other hand, many people argue that AI is not truly intelligent and that it can only do what it has been trained to do.
  <br v-for="_ in 2">

  So what is intelligence?<br>
  Is it simply the ability to solve problems and achieve certain tasks, or is there something more to it?<br>
  So, let's take a look at the definition of intelligence <b>(cambridge dictionary)</b>.<br>
  Some researchers argue that true intelligence involves the use creativity.
  And they argue by saying that AI is not yet capable of doing so but are we sure that human creativity is not just some kind of learning process that has been trained with past experiences?
  <br v-for="_ in 2">

  My personal opinion is that both AI and humans do all the things they do because they have been trained to do it.<br>
  And so AI is truly intelligent and can learn from experience just like humans can.
</template>